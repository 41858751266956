<script>
import { ref } from "vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import addButton from "../../../components/widgets/addButton.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
import sectionVideoCard from "./component/sectionVideoCard.vue";
import loaderProgress from "../../../components/widgets/loaderProcess.vue";

/**
 * Projects-create component
 */
export default {
  page: {
    title: "Video list",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    addButton,
    sectionVideoCard,
    loaderProgress,
  },
  data() {
    return {
      process: false,
      spinner: false,
      progress: 0,
      titre: "",
      searchQuery: "",
      searchProgram: "",
      addMovieModal: false,
      choiceProgramModal: false,
      addSectionMovieModal: false,
      addSectionProgramModal: false,
      addSectionImageModal: false,
      SectionMovieModalSelectList: false,
      SectionProgramModalSelectList: false,
      title: "Sections de vidéos",
      items: [
        {
          text: "Sections",
          active: false,
        },
        {
          text: "Vidéos",
          active: true,
        },
      ],
      daterange: ref(new Date()),
      videos: [],
      programs: [],
      tags: [],
      programmes: [],
      categorieList: [],
      idPrograms: [],
      idVideos: [],
      sectionPrograms: {
        title: "",
        description: "",
      },
      sectionVideos: {
        title: "",
        description: "",
      },
      sectionImage: {
        title: "",
        description: "",
      },
      sectionsVideoList: [],
      sectionsImageList: [],
      sectionsProgramsList: [],
      filesImages: [],
      listeDesSectionDesVideos: [],
    };
  },
  methods: {
    showAddSectionMovieModal() {
      this.addMovieModal = false;
      this.addSectionMovieModal = true;
      this.SectionMovieModalSelectList = false;
    },
    showSectionMovieModalSelectList() {
      this.addSectionMovieModal = false;
      this.SectionMovieModalSelectList = true;
    },

    formatDuration(duration) {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = Math.floor(duration % 60);

      const hoursString = hours.toString().padStart(2, "0");
      const minutesString = minutes.toString().padStart(2, "0");
      const secondsString = seconds.toString().padStart(2, "0");

      return `${hoursString}:${minutesString}:${secondsString}`;
    },
    formatDateByMonth(date) {
      const d = new Date(date);

      const day = d.getDate();
      const month = d.toLocaleString("fr-FR", { month: "long" });
      const year = d.getFullYear();

      return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
    },
    getVideos() {
      Api.get("/streamvodv2/api/v2/video/all")
        .then((res) => {
          this.videos = res.data.content;
          console.log(res);
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
    getSectionVideo() {
      Api.get("/streamvodv2/api/v2/video-section/all")
        .then((response) => {
          const result = response.data.content;
          // this.listeDesSectionDesVideos = response.data.content;
          result.forEach((item) => {
            if (item.isOnHomePage == true) {
              this.sectionsVideoList.push(item);
              this.listeDesSectionDesVideos.push(item);
            }
          });
          this.spinner = false;
        })
        .catch((error) => {
          this.spinner = false;
          Erreur.gestionErreur(error.message);
        });
    },

    createSectionVideo() {
      this.process = true;
      this.SectionMovieModalSelectList = false;
      this.addMovieModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Ajout d'une section de vidéo";

      Api.post(
        `/streamvodv2/rest/v2/video-section/create`,
        {
          videoIds: this.idVideos,
          videoSection: {
            description: this.sectionVideos.description,
            isInProgram: false,
            isOnHomePage: true,
            title: this.sectionVideos.title,
          },
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Créée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
  },

  computed: {
    filteredVideoList() {
      if (!this.searchQuery) {
        return this.videos;
      }
      return this.videos.filter((video) => {
        const fullName = video.title;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },

  mounted() {
    this.spinner = true;
    this.getVideos();
    this.getSectionVideo();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProgress :visible="process" :progress="progress" :title="titre" />

    <div
      style="display: flex; justify-content: space-between; margin-bottom: 2rem"
    >
      <div></div>
      <addButton
        @click="showAddSectionMovieModal()"
        :text="'Créer une section'"
      ></addButton>
    </div>

    <!-- Liste des sections -->

    <div style="text-align: center; font-size: 3em" v-if="spinner == true">
      <b-spinner variant="success" role="status"></b-spinner>
    </div>
    <div
      style="text-align: center; font-size: 3em"
      v-if="spinner == false && listeDesSectionDesVideos.length == 0"
    >
      <span style="font-size: 1.5rem">Aucune section trouvée !</span>
    </div>

    <div class="row">
      <sectionVideoCard
        v-for="section in listeDesSectionDesVideos"
        :key="section.id"
        :section="section"
        :video-list="videos"
        :program-list="programmes"
        :tag-list="tags"
        :categorie-list="categorieList"
      ></sectionVideoCard>
    </div>

    <!-- Modal pour la création d'une section de vidéo -->
    <b-modal
      v-model="addSectionMovieModal"
      id="modal-xl"
      size="xl"
      title="Créer un section de vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionVideos.title"
                  placeholder="Entrez le titre"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionVideos.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div style="display: flex; align-items: center; justify-content: end">
          <button
            @click="showSectionMovieModalSelectList()"
            class="btn c2play-primary"
          >
            suivant
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="SectionMovieModalSelectList"
      id="modal-xl"
      size="xl"
      title="Créer un section de vidéo"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <button
              @click="showAddSectionMovieModal()"
              class="btn c2play-primary"
            >
              Retour
            </button>
          </div>
          <div>
            <button @click="createSectionVideo()" class="btn c2play-primary">
              Ajouter
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>

          <div class="col-lg-6" style="text-align: end">
            {{ idVideos.length }} vidéo<span v-if="idVideos.length > 1">s</span>
            séléctionée<span v-if="idVideos.length > 1">s</span>
          </div>
        </div>

        <br />

        <div class="table-responsive" style="height: 80vh; overflow: scroll">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col" style="width: 20px">Select</th>
                <th scope="col" style="width: 100px">Image</th>
                <th scope="col" style="width: 400px">Titre</th>
                <th scope="col">Date de publication</th>
                <th scope="col">Durée</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="video in filteredVideoList.slice().reverse()"
                :key="video.id"
              >
                <td>
                  <b-form-checkbox
                    v-model="idVideos"
                    :value="video.id"
                    name="checkbox-1"
                  ></b-form-checkbox>
                </td>
                <td>
                  <img
                    :src="video.thumbnail"
                    alt="image"
                    height="60"
                    width="100"
                    style="border-radius: 7px"
                  />
                </td>
                <td>{{ video.title }}</td>
                <td>{{ formatDateByMonth(video.releaseDate) }}</td>
                <td>{{ formatDuration(video.duration) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- =============================================================================================== -->

    <!-- end row -->
  </Layout>
</template>
